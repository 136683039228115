.internship-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh; /* Adjust as needed */
  background: linear-gradient(to bottom, #001f3f, #000);
}

.category {
  width: 500px; /* Set a fixed width for each category */
  margin: 20px;
}

.category-link {
  display: block;
  text-decoration: none;
  font-size: 1.5rem;
  color: #007bff; /* Adjust link color */
  padding: 10px 20px;
  border: 2px solid #007bff; /* Adjust border color */
  border-radius: 5px;
  text-align: center; /* Center align text */
  transition: all 0.3s ease;
}

.category-description {
  display: block;
  font-size: 1rem;
  color: #cccccc; /* Subtle color for the description */
  margin-top: 5px; /* Spacing between title and description */
}

.category-link:hover {
  background-color: #007bff;
  color: #ffffff;
}

.category-link:hover .category-description {
  color: #ffffff; /* Change description color on hover for consistency */
}

.category-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3); /* Adjust focus style */
}

/* Media query for tablets and smaller screens */
@media (max-width: 768px) {
  .category {
    width: 100%; /* Full width on smaller screens */
    margin: 10px 0; /* Adjust margin for better spacing */
  }

  .category-link {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }

  .category-description {
    font-size: 0.9rem; /* Slightly smaller description font on tablets */
  }
}

/* Media query for very small screens (e.g., smartphones in portrait mode) */
@media (max-width: 480px) {
  .internship-wrapper {
    height: 80vh; /* Allow height to adjust based on content */
    padding: 20px; /* Add padding for better spacing */
  }

  .category {
    width: 100%; /* Full width on very small screens */
    margin: 10px 0; /* Adjust margin for better spacing */
  }

  .category-link {
    font-size: 1.3rem; /* Adjust font size for very small screens */
    padding: 20px 12px; /* Adjust padding for very small screens */
  }

  .category-description {
    font-size: 1rem; /* Keep readable font size for descriptions */
  }
}
