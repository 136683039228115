.specialization-areas {
  padding: 40px 20px;
  background: linear-gradient(to bottom, #000, #001f3f);
  color: #fff;
}

.section-header {
  text-align: center;
  margin-bottom: 30px;
}

.section-header h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.section-header p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: auto;
}

.categories {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.category {
  flex: 1 1 12%;
  text-align: center;
  margin: 10px;
}

.category h3 {
  font-size: 1.1rem;
  margin-top: 10px;
}

.category-icon {
  font-size: 3rem;
  color: #fff;
}

/* Media queries for responsiveness */

@media (max-width: 1200px) {
  .category {
    flex: 1 1 20%;
  }
}

@media (max-width: 992px) {
  .category {
    flex: 1 1 30%;
  }
}

@media (max-width: 768px) {
  .category {
    flex: 1 1 45%;
  }

  .section-header h2 {
    font-size: 1.8rem;
  }

  .section-header p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .specialization-areas{
    gap: 20px;
  }
  .category {
    flex: 1 1 100%;
  }

  .section-header h2 {
    font-size: 1.5rem;
  }

  .section-header p {
    font-size: 0.9rem;
  }
}
