.registration-form-container {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  color: #fff; /* Text color */
}

.registration-form-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white background for input fields */
  color: #fff; /* Text color */
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select:focus {
  outline: none;
  border-color: #007bff; /* Highlight color on focus */
}

/* Adjust select background color */
select {
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
}

small {
  font-size: 12px;
  color: #fff; /* Small text color */
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
