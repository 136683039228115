.navbar {
    z-index: 1000;
    background: linear-gradient(to bottom, #000, #001f3f 0%);
    padding: 1em 2.5rem;
    position: sticky;
    top: 0;
    width: 100%;
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }
  
  .nav-wrapper {
    max-width: 80rem;
    margin: auto; /* Center align the content */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
  }
  
  .nav-main_logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .nav-main_logo {
    height: 50px; /* Adjust as necessary */
    margin-right: 10px; /* Space between logo and text */
    
  }
  
  .logo-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #007bff;
  }
  
  .logo-text h1 {
    margin: 0;
    line-height: 1;
    font-size: 1.25rem;
  }
  
  .logo-text .line {
    width: 100%;
    height: 1px;
    background-color: #fff; /* Line color */
    margin: 5px 0; /* Space between the text and the line */
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
  }
  
  .nav-main_link {
    margin: 0 15px; /* Adjust as necessary */
    text-decoration: none;
    color: #ffffff; /* White color for links */
    display: flex;
    align-items: center;
  }
  
  .nav-main_link:hover,
  .nav-main_link.active {
    color: #edda07; /* Highlight color for the active page and hover */
  }
  
  .nav-main_button {
    margin: 0 15px; /* Adjust as necessary */
    text-decoration: none;
    color: #fff;
    background-color: #007bff; /* Blue color for button */
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .nav-main_button:hover {
    background-color: #0056b3; /* Darker blue color on hover */
  }
  
  .text-block {
    font-size: 16px; /* Adjust as necessary */
  }
  
  .nav-main_menu-mobile-button {
    display: none; /* Hide by default, show in responsive view */
  }
  
  .nav-main_menu-mobile-icon {
    height: 24px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .nav-menu {
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px; /* Adjust as necessary */
      left: 0;
      border-top: 1px solid #ddd;
      padding: 10px 0;
      background: linear-gradient(to bottom, #000, #001f3f 0%);
    }
  
    .nav-main_menu-mobile-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 40px;
      border-radius: 5px;
    }
  
    .nav-main_menu-mobile-icon {
      height: 24px;
      fill: #fff; /* White icon color */
    }
  
    .nav-menu.open {
      display: flex;
    }
  
    .nav-main_link {
      margin: 10px 0;
      justify-content: center;
    }
  }
  
  /* To fix the horizontal margin issue */
  body {
    margin: 0; /* Remove default body margin */
    padding: 0; /* Remove default body padding */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  