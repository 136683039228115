.featured-projects {
    padding: 40px 20px;
    background: linear-gradient(to bottom, #001f3f, #000);
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 30px;
    color: #fff;
  }
  
  .section-header h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .section-header p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: auto;
  }
  
  .project-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .project-card {
    flex: 1 1 250px;
    background-color: #1a1a1a;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    text-align: center;
    position: relative;
    color: #fff;
    padding: 10px;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
  }
  
  .project-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .title {
    font-size: 0.9rem;
    margin: 5px 0;
    color: #0074d9;
  }
  
  .description {
    font-size: 0.8rem;
    padding: 0 10px;
    margin-bottom: 5px;
  }
  
  .project-info {
    padding: 10px 0;
  }
  
  .project-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .project-details h3 {
    font-size: 1rem;
    margin: 0;
  }
  
  .rating {
    font-size: 0.9rem;
  }
  
  .project-links-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .social-links a {
    color: #0074d9;
    font-size: 1.2rem;
    margin-left: 10px;
  }
  
  .price {
    font-size: 1rem;
    color: #ff4136;
  }
  