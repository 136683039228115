/* Ensure to apply the same background gradient as in the header and footer */
.feature-content-wrapper {
  padding: 60px 20px;
  background: linear-gradient(to bottom, #000, #001f3f);
  color: #fff; /* White text for contrast */
  text-align: center; /* Centered text for better visual appeal */
}

.section-title {
  margin-bottom: 40px;
}

.heading-large {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.feature-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow features to wrap to the next line if needed */
  gap: 40px; /* Space between features */
}

.feature {
  
  background-color: white; /* Semi-transparent background for features */
  padding: 10px;
  border-radius: 10px;
  width: 220px; /* Fixed width for consistency */
  text-align: center;
  box-shadow: 0 4px 8px rgba(189, 185, 185, 0.2); /* Slight shadow for depth */
  transition: transform 0.3s ease; /* Smooth transform transition */
}

.feature:hover {
  transform: translateY(-10px); /* Lift effect on hover */
}

.icon-wrapper {
  margin-bottom: 20px;
  height: 48px; /* Ensure all icons fit within the same height */
  display: flex;
  justify-content: center;
  align-items: center;
  
  border-radius: 50px;
}


.feature h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #6e08f3;
}

.feature p {
  font-size: 1.0rem;
  font-weight: 300;
  line-height: 1.5;
  color: #03172b;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .feature {
    width: 100%; /* Full width for each feature on smaller screens */
    margin-bottom: 20px; /* Space between features on smaller screens */
  }

  .heading-large {
    font-size: 2rem;
  }
}
