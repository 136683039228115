/* HowItWorks.css */

.process-steps {
  padding: 40px 20px;
  background: linear-gradient(to bottom, #000, #001f3f); /* Dark background for contrast */
  color: #fff;
}

.process-header {
  text-align: center;
  margin-bottom: 30px;
}

.process-header h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #fff;
}

.process-header p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: auto;
  color: #cfd8dc; /* Light gray for better readability */
}

.process-steps-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.process-step {
  flex: 1 1 280px;
  padding: 20px;
  background-color: #001733;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 300px;
}

.process-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.process-step i {
  font-size: 3rem;
  color: #4fc3f7; /* Accent color for icons */
  margin-bottom: 15px;
}

.process-step h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.process-step p {
  font-size: 1.1rem;
  color: #cfd8dc; /* Light gray for text */
}

/* Media Queries */

/* For tablets and smaller screens */
@media (max-width: 768px) {
  .process-header h2 {
    font-size: 2rem;
  }

  .process-step {
    padding: 15px;
  }

  .process-step h3 {
    font-size: 1.3rem;
  }

  .process-step p {
    font-size: 1rem;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .process-steps {
    padding: 20px 10px;
  }

  .process-header h2 {
    font-size: 1.8rem;
  }

  .process-header p {
    font-size: 1rem;
  }

  .process-step {
    padding: 10px;
    max-width: 100%; /* Full width for small screens */
  }

  .process-step i {
    font-size: 2.5rem;
  }

  .process-step h3 {
    font-size: 1.2rem;
  }

  .process-step p {
    font-size: 0.95rem;
  }
}
