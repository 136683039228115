/* Full Banner Styles */
.full-banner {
  background: linear-gradient(to bottom, #000, #001f3f);
  color: #fff;
  padding: 90px 20px; /* Added horizontal padding for mobile views */
  text-align: center;
  box-sizing: border-box; /* Ensures padding is included in the total width/height */
}

.banner-heading {
  font-size: 3.5em; /* Increased for better visibility */
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1.2; /* Adds line height for better spacing */
}

.highlight-text {
  color: #edda07; /* Highlight color */
  font-weight: bold;
}

.banner-text {
  font-size: 1.5em;
  margin-bottom: 40px;
  line-height: 1.5;
  color: #ddd; /* Lighter color for better contrast */
}

.banner-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.banner-button:hover {
  background-color: #0056b3;
  transform: translateY(-5px); /* Adds hover effect */
}

.banner-button:active {
  transform: translateY(2px); /* Gives a pressed effect */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .banner-heading {
      font-size: 2.5em; /* Scales down for smaller screens */
  }
  .banner-text {
      font-size: 1.2em; /* Smaller text on mobile */
  }
  .banner-button {
      font-size: 1em; /* Smaller button text */
      padding: 10px 20px; /* Adjusted padding */
  }
}

@media (max-width: 480px) {
  .full-banner {
      padding: 70px 10px; /* Reduced padding on very small screens */
  }
  .banner-heading {
      font-size: 2.2em; /* Scales down further for smaller devices */
  }
  .banner-text {
      font-size: 1em; /* Adjusts font size for readability */
  }
  .banner-button {
      font-size: 0.9em;
      padding: 8px 16px; /* More compact button */
  }
}
