.footer-area {
  padding-top: 40px;
  background: linear-gradient(to bottom, #001f3f, #000);
  color: #fff;
}

.row {
  display: flex;
  justify-content: center; /* Centers columns horizontally */
  gap: 50px;
  width: 100%;
  margin: 20px;
}

.single-footer-widget {
  margin-bottom: 30px;
  width: 250px;
}

.single-footer-widget .logo img {
  max-width: 60px;
  margin: 5px;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.single-footer-widget p {
  margin-top: 15px;
  font-size: 14px;
  text-align: justify;
}

.single-footer-widget h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
}

.single-footer-widget ul.list {
  padding-left: 0;
  list-style: none;
}

.single-footer-widget ul.list li {
  margin-bottom: 10px;
}

.single-footer-widget ul.list li a {
  color: #ddd;
  font-size: 14px;
  text-decoration: none;
}

.single-footer-widget ul.list li a:hover {
  color: #007bff;
}

.footer-contact-info {
  padding-left: 0;
  list-style: none;
}

.footer-contact-info li {
  margin-bottom: 10px;
  display: flex; /* Ensures items are aligned correctly */
  align-items: center; /* Vertically center items */
}

.footer-contact-info li svg {
  margin-right: 10px;
}

.footer-bottom {
  padding: 10px;
  color: #fff; /* Light text color for contrast */
  text-align: center; /* Centers text in the footer bottom */
}

.copyright-area p {
  margin: 0;
  font-size: 14px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .row {
    flex-direction: column; /* Stacks columns vertically on smaller screens */
    gap: 20px; /* Decrease the gap between widgets */
  }

  .single-footer-widget {
    width: 100%; /* Full width for each widget on smaller screens */
  }
}
