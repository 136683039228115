.tradex-header {
  text-align: center;
  background: linear-gradient(to bottom, #001f3f, #000);
  position: relative;
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.welcome-message {
  font-size: 6rem;
  font-weight: 500;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-bottom: 20px;
}

.banner-content {
  position: relative;
  z-index: 1;
}

.tradex-header h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
}

.tradex-header p {
  font-size: 1.5rem;
  max-width: 800px;
  margin: auto;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  margin: 20px auto 0;
}

/* Media queries for responsiveness */

@media (max-width: 1200px) {
  .welcome-message {
    font-size: 5rem;
  }

  .tradex-header h1 {
    font-size: 3rem;
  }

  .tradex-header p {
    font-size: 1.4rem;
  }
}

@media (max-width: 992px) {
  .welcome-message {
    font-size: 4.5rem;
  }

  .tradex-header h1 {
    font-size: 2.5rem;
  }

  .tradex-header p {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .welcome-message {
    font-size: 4rem;
  }

  .tradex-header h1 {
    font-size: 2rem;
  }

  .tradex-header p {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .welcome-message {
    font-size: 3.5rem;
  }

  .tradex-header h1 {
    font-size: 1.8rem;
  }

  .tradex-header p {
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .welcome-message {
    font-size: 3rem;
  }

  .tradex-header h1 {
    font-size: 1.5rem;
  }

  .tradex-header p {
    font-size: 0.9rem;
  }
}
