.about-us-container {
  background: linear-gradient(to bottom, #001f3f, #000);
  color: #fff;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  
  

}

.about-content {
  max-width: 700px;
  margin-right: 20px;
  text-align: left;
}

.about-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-subheading {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: blueviolet;
}

.about-text {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 200;
  color: #ff1f8f;
  margin-bottom: 30px;
}

.about-quotation {
  font-size: 1.4rem;
  font-style: italic;
  margin: 30px 0;
}

.earth-animation {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.earth-img {
  width: 400px;
  animation: rotateEarth 20s linear infinite;
}

@keyframes rotateEarth {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .about-us-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .about-content {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about-heading {
    font-size: 2rem;
  }

  .about-subheading {
    font-size: 1.5rem;
  }

  .about-text {
    font-size: 1rem;
  }

  .about-quotation {
    font-size: 1.2rem;
  }

  .earth-img {
    width: 300px;
  }
}
