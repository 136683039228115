/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



/* Roadmap Component */
.roadmap_component {
    padding: 50px 20px;
    background: linear-gradient(to bottom, #001f3f, #000);
    color: #fff;
}

/* Roadmap Section */
.roadmap {
    padding: 3rem 2rem;
    max-width: 1000px;
    margin: auto;
    text-align: center;
}

/* Section Header */
.section-header .heading {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #fff;
    font-weight: bold;
}

.section-header p {
    font-size: 1.2rem;
    color: #ddd;
}

/* Steps Container */
.steps {
    position: relative;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #ddd;
    transform: translateX(-50%);
}

/* Individual Step Styling */
.step {
    position: relative;
    padding: 2.5rem 1.5rem;
    width: 85%;
    background: #f8f8f8;
    border-radius: 10px;
    margin: 2.5rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 1.1rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    color: #333;
}

.step:hover {
    transform: translateY(-10px);
}

/* Even and Odd Step Alignment */
.step:nth-child(even) {
    margin-left: auto;
}

.step:nth-child(odd) {
    margin-right: auto;
}

/* Step Icon */
.step-icon {
    font-size: 3.5rem;
    color: #033365;
    margin-right: 1.5rem;
}

/* Step Title */
.step h3 {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
    
}

/* Step Description */
.step p {
    margin: 1.5rem;
    color: #666;
    font-size: 1.1rem;
    line-height: 1.6;
}

/* Step Circle Indicator */
.step::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    background: #007bff;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: -50px;
}

/* Even Step Circle Position */
.step:nth-child(even)::before {
    left: auto;
    right: -50px;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
    .roadmap {
        padding: 2.5rem;
    }
    
    .section-header .heading {
        font-size: 2.5rem;
    }

    .steps::before {
        width: 2px;
    }

    .step {
        width: 90%;
        padding: 2rem;
    }

    .step-icon {
        font-size: 2.5rem;
    }

    .step h3 {
        font-size: 1.3rem;
    }

    .step p {
        font-size: 1rem;
    }

    .step::before {
        width: 22px;
        height: 22px;
        left: -40px;
    }

    .step:nth-child(even)::before {
        right: -40px;
    }
}

@media (max-width: 768px) {
    .roadmap_component {
        padding: 40px 15px;
    }

    .roadmap {
        padding: 2rem;
    }

    .section-header .heading {
        font-size: 2rem;
    }

    .steps {
        margin-top: 3rem;
    }

    .steps::before {
        width: 2px;
    }

    .step {
        width: 100%;
        padding: 1.5rem;
        font-size: 1rem;
    }

    .step-icon {
        font-size: 2rem;
        margin-right: 1rem;
    }

    .step h3 {
        font-size: 1.2rem;
    }

    .step p {
        font-size: 1rem;
    }

    .step::before {
        width: 20px;
        height: 20px;
        left: -30px;
    }

    .step:nth-child(even)::before {
        right: -30px;
    }
}

@media (max-width: 480px) {
    .roadmap_component {
        padding: 30px 10px;
    }

    .roadmap {
        padding: 1.5rem;
    }

    .section-header .heading {
        font-size: 1.8rem;
    }

    .steps::before {
        left: 10%;
    }

    .step {
        width: 100%;
        padding: 1rem;
        font-size: 0.9rem;
    }

    .step-icon {
        font-size: 1.6rem;
        margin-right: 0.8rem;
    }

    .step h3 {
        font-size: 1rem;
    }

    .step p {
        font-size: 0.9rem;
    }

    .step::before {
        width: 18px;
        height: 18px;
        left: -25px;
    }

    .step:nth-child(even)::before {
        right: -25px;
    }
}

@media (max-width: 320px) {
    .roadmap_component {
        padding: 25px 10px;
    }

    .roadmap {
        padding: 1rem;
    }

    .section-header .heading {
        font-size: 1.6rem;
    }

    .step {
        padding: 0.8rem;
        font-size: 0.85rem;
    }

    .step-icon {
        font-size: 1.5rem;
        margin-right: 0.6rem;
    }

    .step h3 {
        font-size: 0.9rem;
    }

    .step p {
        font-size: 0.8rem;
    }

    .step::before {
        width: 16px;
        height: 16px;
        left: -20px;
    }

    .step:nth-child(even)::before {
        right: -20px;
    }
}
