/* Universal styles for all elements to avoid unwanted margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Use border-box for consistent sizing */
}

/* General body styles */
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;  /* Use Poppins font as priority */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Code block styling */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Container styles for responsive layout */
.container {
  max-width: 1200px;  /* Limit max width for large screens */
  width: 100%;  /* Full width */
  margin: 0 auto;  /* Center the container */
  padding: 16px;  /* Padding around content */
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap */
  justify-content: space-between; /* Align items with space between */
}

/* Default styles for items */
.item {
  flex: 1 1 calc(33.333% - 32px);  /* Three items per row with spacing */
  margin: 16px;  /* Add margin for spacing */
  background-color: #fff;  /* White background for items */
  color: #333;  /* Text color */
  border-radius: 8px;  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Subtle shadow */
  transition: transform 0.3s;  /* Smooth transformation */
}

/* Hover effect for items */
.item:hover {
  transform: scale(1.05);  /* Slightly increase size on hover */
}

/* Responsive design for mobile screens */
@media (max-width: 768px) {
  .item {
    flex: 1 1 calc(50% - 16px);  /* Two items per row */
  }
}

/* Responsive design for very small screens */
@media (max-width: 480px) {
  .item {
    flex: 1 1 100%;  /* One item per row */
    margin: 8px 0;  /* Reduce margin for smaller screens */
  }
}
